import { AnalyticsBrowser } from '@segment/analytics-next';
import { store } from 'redux/store';

export interface IFormTrackEvent {
  address_1?: string;
  address_2?: string;
  city?: string;
  zip?: number;
  birthdate?: string;
  middle_name?: string;
  lead_email?: string;
  phone_number?: string;
  form_state_name?: string;
  form_dispensary_name?: string;
  first_name?: string;
  last_name?: string;
  form_type?: string;
  form_details?: object;
}

export interface IFormCloseEvent {
  state_name?: string;
  store_id?: string;
  dispensary_name?: string;
  form_type: string;
  method: string;
}

export interface IMenuChangeToggle {
  menu_label: string;
  page_location: string;
}

export interface IDealClicked {
  placement: number;
  deal_position: number;
  deal_name: string;
  deal_title: string;
  deal_image: string;
  deal_type: string;
  link_name?: string;
  banner_scroll_direction?: string;
}
export interface INavigartionTrack {
  nav_position: string;
  link_primary?: string;
  link_secondary?: string;
  link_final: string;
  link_type: string;
  page_location: string;
}

export interface TSegementTrackEventConfig {
  sendGeoData?: boolean;
}

export interface ICategoriesTrackEvent {
  category_name?: string;
  categories?: Array<string>;
}

export interface IAgeGateTrackEventProps {
  terms_of_service: boolean;
  privacy_policy: boolean;
  notice_of_privacy_practices: boolean;
  response: boolean;
}

export interface ISortSelectEvent {
  placement: number;
  card_list_name: string;
  card_list_title: string;
  card_list_type: string;
  sorted_by: string;
}
export interface Iproducts {
  product_id: string;
  product_name: string;
  product_brand: string;
  product_category: string;
  product_lineage: string;
  product_weight: string;
  product_actual_price: string;
  product_quantity: string;
  product_offer_applied: string;
  product_discounted_price: string;
}
export interface ICompleteCheckOutEvent {
  cart_id: string;
  cart_uuid: string;
  cart_subtotal: string;
  cart_service_fee: string;
  cart_delivery_fee: string;
  cart_store_tax: string;
  cart_sales_tax: string;
  cart_savings: string;
  delivery_start_time: string;
  cart_estimated_total: string;

  reservation_type: string;
  payment_method: string;
  products: Iproducts[];
}
export interface ICartViewedEvent {
  cart_empty: boolean;
  cart_subtotal: string;
  cart_estimated_total: string;
  cart_trigger: string;
  cart_uuid: string;
  cart_savings: string;
}

export interface ICartProductAddedEvent {
  placement: number;
  cart_uuid: string;
  product_position: number;
  product_id: string;
  product_name: string;
  product_brand: string;
  product_category: string;
  product_lineage: string;
  product_weight: string;
  product_actual_price: string;
  product_quantity: number;
  product_offer_applied: string;
  product_discounted_price: string;
  add_to_cart_trigger: string;
}

export interface IBannerEvent {
  placement?: number;
  banner_position?: number;
  banner_internal_name: string;
  banner_title: string;
  banner_image_main?: string;
  banner_image_additional?: string;
  banner_image_overlay?: string;
  banner_type: string;
  link_name: string;
  link_type: string;
  page_location: string;
  banner_scroll_direction?: string;
}

export interface ICardListEvent {
  card_list_name: string;
  card_list_title: string;
  card_list_type?: string;
  card_list_facet_name?: string;
  card_list_facet_value?: string;
  no_results: boolean;
}
export interface ICardListClickedEvent extends ICardListEvent {
  card_list_url: string;
  link_name: string;
  link_type: string;
}

export interface ICardListFilteredEvent extends ICardListEvent {
  filters_applied: Array<{
    filter_type: string;
    filter_values?: Array<string>;
  }>;
  page_location: string;
}

export interface IChangeDispensaryEvent {
  final_state_name: string;
  final_dispensary_id: string;
}

export interface ICommunicationInitiated {
  method: string;
  link_name: string;
  link_type: string;
  communication_trigger: string;
}

const SEGMENT_WRITE_KEY = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY;
const segmentAnalytics = AnalyticsBrowser.load(
  { writeKey: SEGMENT_WRITE_KEY || '' },
  {
    cookie: {
      domain: typeof window !== 'undefined' ? window?.location?.host : '',
    },
  },
);

export const SegementTrackEvent = async (eventName: string, eventData: object, config?: TSegementTrackEventConfig) => {
  try {
    if (config && config.sendGeoData) {
      const { currentDispensary } = store?.getState()?.dispensaryList;
      eventData['state_name'] = String(currentDispensary?.state?.state?.stateName.toLocaleLowerCase() || '');
      eventData['dispensary_id'] = String(currentDispensary?.dispensaries?.storeId || '');
    }
    return await segmentAnalytics.track(eventName, eventData);
  } catch (error) {
    console.log('ERROR SegementTrackEvent::', error);
  }
};

export const SegementPageEvent = async (data) => {
  try {
    return await segmentAnalytics.page(data);
  } catch (error) {
    console.log('ERROR SegementPageEvent::', error);
  }
};

export const SegementIdentifyEvent = async (userAttribute) => {
  try {
    const userID = userAttribute.sub;
    const { springbig_id, annexcloud_id } = JSON.parse(userAttribute['custom:external_usr_mapping']);
    const eventData = {
      birthdate: new Date(userAttribute?.birthdate).toISOString(),
      current_dispensary: userAttribute['custom:current_dispensary'],
      current_state: userAttribute['custom:current_state'],
      email: userAttribute.email,
      email_verified: userAttribute.email_verified === 'true' ? true : false,
      last_name: userAttribute.family_name,
      first_name: userAttribute.given_name,
      home_state: userAttribute['custom:home_state'],
      phone_number: userAttribute.phone_number,
      phone_number_verified: userAttribute.phone_number_verified === 'true' ? true : false,
      profile_created: userAttribute['custom:profile_created'] === 'true' ? true : false,
      jane_id: userID,
      loyalty_id: `${springbig_id}` || `${annexcloud_id}`,
      loyalty_system: springbig_id ? 'springbig' : 'annex-cloud',
    };
    return await segmentAnalytics.identify(userID, { ...eventData });
  } catch (error) {
    console.log('ERROR SegementIdentifyEvent::', error);
  }
};

interface IPropsGuestUserIdebtify {
  email: string;
  phone_number: string;
}

export const SegementIdentifyEventGuestUser = async (userAttribute: IPropsGuestUserIdebtify) => {
  try {
    const eventData = {
      email: userAttribute.email,
      phone_number: userAttribute.phone_number,
    };
    return await segmentAnalytics.identify({ ...eventData });
  } catch (error) {
    console.log('ERROR SegementIdentifyEventGuestUser::', error);
  }
};

export const SegementResetEvent = async () => {
  try {
    return await segmentAnalytics.reset();
  } catch (error) {
    console.log('ERROR SegementResetEvent::', error);
  }
};

export const getSegmentAnonymousId = async () => {
  let anonymousId;
  anonymousId = localStorage.getItem('ajs_anonymous_id');
  if (!anonymousId) {
    const res = await segmentAnalytics.user();
    anonymousId = res.anonymousId;
  }
  return anonymousId;
};
