'use client';

import { createSlice } from '@reduxjs/toolkit';

interface SearchState {
  data: {
    productsData?: number;
    isProductsLoading: boolean;
    dispensariesData?: number;
    isDispensariesLoading: boolean;
    articlesData?: number;
    isArticlesLoading: boolean;
    sortValue: string;
    // TODO: replace any with proper type
    searchBar: { isOpen: boolean; data?: any };
  };
}

const initialState: SearchState = {
  data: {
    productsData: 0,
    isProductsLoading: false,
    dispensariesData: 0,
    isDispensariesLoading: false,
    articlesData: 0,
    isArticlesLoading: false,
    sortValue: '',
    searchBar: { isOpen: false, data: null },
  },
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    updateSearch: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    clearSearchState: (state) => {
      state.data = initialState.data;
    },
  },
});

export const { updateSearch, clearSearchState } = searchSlice.actions;

export default searchSlice.reducer;
