'use client';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IImage } from 'helpers/types/forms';

export type TCurrentDispensary = {
  dispensaries?: {
    dispensaryInformation: any;
    heroImage: IImage;
    dispensaryName?: string;
    menusCollection: {
      items: any;
    };
    showChatBox: boolean;
    slug: string;
    storeId?: string;
    dispensaryTags: string[];
  };
  state?: {
    slug: string;
    state: {
      stateCode: string;
      stateName: string;
    };
  };
};
interface DispensaryList {
  currentDispensary: TCurrentDispensary;
  dispensaryList: TCurrentDispensary[];
}

const initialState: DispensaryList = {
  currentDispensary: {
    dispensaries: {
      dispensaryInformation: {},
      heroImage: {
        url: '',
        title: '',
        height: 0,
        width: 0,
      },
      menusCollection: {
        items: [],
      },
      showChatBox: false,
      slug: '',
      dispensaryTags: [],
    },
    state: {
      slug: '',
      state: {
        stateCode: '',
        stateName: '',
      },
    },
  },
  dispensaryList: [],
};

const DispensaryListSlice = createSlice({
  name: 'dispensaryList',
  initialState,
  reducers: {
    setDispensaryList: (state, action: PayloadAction<DispensaryList>) => {
      state.currentDispensary = action.payload.currentDispensary;
      state.dispensaryList = action.payload.dispensaryList;
    },
  },
});

export const { setDispensaryList } = DispensaryListSlice.actions;

export default DispensaryListSlice.reducer;
