'use client';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ICookieGroupPermissions {
  cookieGroups: string;
}

const initialState: ICookieGroupPermissions = {
  cookieGroups: 'C0001:0,C0002:0,C0003:0,C0004:0,C0005:0',
};

const cookieSlice = createSlice({
  name: 'cookiePermissions',
  initialState,
  reducers: {
    setCookiePermissions: (state, action: PayloadAction<ICookieGroupPermissions>) => {
      state.cookieGroups = action.payload.cookieGroups;
    },
  },
});

export const { setCookiePermissions } = cookieSlice.actions;

export default cookieSlice.reducer;
