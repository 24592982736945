import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  CookieStorage,
  ICognitoUserAttributeData,
} from 'amazon-cognito-identity-js';

type TEmailVerificationType = 'link' | 'code';

type User = CognitoUser & { attributes: any };
class AuthClass {
  private userPool: CognitoUserPool;
  private currentUser: CognitoUser;

  constructor() {
    const poolData = {
      UserPoolId: process.env.NEXT_PUBLIC_AMS_POOLID || '',
      ClientId: process.env.NEXT_PUBLIC_AMS_CLIENTID || '',
      AuthFlow: 'CUSTOM_AUTH',
      Storage: new CookieStorage(),
    };
    this.userPool = new CognitoUserPool(poolData);
  }

  getCurrentUser() {
    return this.userPool.getCurrentUser();
  }

  getCognitoUser(username: string) {
    const userData = {
      Username: username,
      Pool: this.userPool,
      Storage: new CookieStorage(), //{ domain: window?.location?.hostname }
    };
    const cognitoUser = new CognitoUser(userData);
    return cognitoUser;
  }

  getUpdatedUser(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getAttributes()
        .then((userProfile: any) => {
          const user = { ...this.currentUser, attributes: userProfile } as User;
          resolve(user);
        })
        .catch(() => {
          reject(null);
        });
    });
  }

  getSession(): Promise<null | CognitoUserSession> {
    if (!this.currentUser) {
      this.currentUser = this.userPool.getCurrentUser() as CognitoUser;
    }
    return new Promise<CognitoUserSession>((resolve, reject) => {
      this.currentUser.getSession((err, session: CognitoUserSession) => {
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    }).catch(() => {
      this.signOut();
      return null;
    });
  }

  signUp(username: string, password: string, attributeList: CognitoUserAttribute[]) {
    return new Promise((resolve, reject) => {
      this.userPool.signUp(username, password, attributeList, [], (err, res) => {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      });
    }).catch((err) => {
      throw err;
    });
  }

  signIn(username: string) {
    return new Promise((resolve, reject) => {
      const authenticationData = {
        Username: username,
      };
      const authenticationDetails = new AuthenticationDetails(authenticationData);

      this.currentUser = this.getCognitoUser(username);
      this.currentUser.setAuthenticationFlowType('CUSTOM_AUTH');
      this.currentUser.challengeName = 'CUSTOM_CHALLENGE';
      this.currentUser.initiateAuth(authenticationDetails, {
        onSuccess: () => {
          this.getAttributes().then((userProfile: any) => {
            resolve(userProfile);
          });
        },
        onFailure: (err: any) => {
          reject(err);
        },
        customChallenge: () => {
          resolve('');
        },
      });
    }).catch((err) => {
      throw err;
    });
  }

  sendCustomChallengeAnswer(answerChallenge: string) {
    return new Promise((resolve, reject) => {
      this.currentUser.sendCustomChallengeAnswer(answerChallenge, {
        onSuccess: () => {
          this.getAttributes().then((userProfile: any) => {
            const user = { ...this.currentUser, attributes: userProfile };
            resolve(user);
          });
        },
        onFailure: (err: any) => {
          reject(err);
        },
        customChallenge: () => {
          resolve({ challengeName: 'CUSTOM_CHALLENGE' });
        },
      });
    });
  }

  signOut() {
    // TODO: implement a solution for signOut
    // if (this.currentUser) {
    //   this.currentUser.signOut();
    //   store.dispatch(setUser(null));
    //   // TODO: fix navigation
    //   // Router.push('/');
    //   const currentStoreId = store.getState().dispensaryList.currentDispensary.dispensaries.storeId;
    //   if (cookieCutter.get('isUser') === 'false') {
    //     cookieCutter.set('isUser', 'false', { expires: new Date(0) });
    //   }
    //   store.dispatch(removeFromCarts({ store_id: currentStoreId }));
    // }
  }

  getAttributes() {
    return new Promise((resolve, reject) => {
      this.currentUser.getUserAttributes((err: any, attributes: any) => {
        if (err) {
          reject(err);
        } else {
          const userProfile = attributes.reduce((acc: any, { Name, Value }: any) => ({ ...acc, [Name]: Value }), {});
          resolve(userProfile);
        }
      });
    }).catch((err) => {
      throw err;
    });
  }

  updateUserAttributes(attribute: ICognitoUserAttributeData[]) {
    return new Promise((resolve, reject) => {
      this.currentUser.updateAttributes(attribute, (err: any) => {
        if (err) {
          reject(err);
        } else {
          resolve(this.getUpdatedUser());
        }
      });
    }).catch((err) => {
      throw err;
    });
  }

  verifyEmail(type: TEmailVerificationType) {
    return new Promise((resolve, reject) => {
      // const clientMetaData =
      //   type === 'link'
      //     ? {
      //         isLink: 'yes',
      //       }
      //     : { isLink: 'no' };

      this.currentUser.getAttributeVerificationCode(
        'email',
        {
          onSuccess: (result) => {
            resolve(result);
          },
          onFailure: (err) => {
            reject(err);
          },
          inputVerificationCode: undefined,
        },
        {
          isLink: type === 'link' ? 'yes' : 'no',
        },
      );
    }).catch((err) => {
      throw err;
    });
  }

  verifyEmailSubmit(code: string) {
    return new Promise((resolve, reject) => {
      this.currentUser.verifyAttribute('email', code, {
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    }).catch((err) => {
      throw err;
    });
  }

  async checkSessionForServer(lastAuthUserEncoded: string): Promise<CognitoUserSession | null> {
    const getCognitoToken = (type: 'accessToken' | 'refreshToken' | 'idToken') => {
      return `${lastAuthUserEncoded}.${type}`;
    };

    const cognitoAccessToken = getCognitoToken('accessToken');
    const cognitoRefreshToken = getCognitoToken('refreshToken');
    const cognitoIdToken = getCognitoToken('idToken');

    if (!lastAuthUserEncoded || !cognitoAccessToken || !cognitoRefreshToken) {
      return null;
    }

    const cognitoUser = this.getCognitoUser(lastAuthUserEncoded);
    console.log('cognitoUser', cognitoUser);
    this.currentUser = cognitoUser;
    cognitoUser.setSignInUserSession(
      new CognitoUserSession({
        AccessToken: new CognitoAccessToken({ AccessToken: cognitoAccessToken }),
        RefreshToken: new CognitoRefreshToken({ RefreshToken: cognitoRefreshToken }),
        IdToken: new CognitoIdToken({ IdToken: cognitoIdToken }),
      }),
    );

    try {
      return await new Promise((resolve, reject) => {
        cognitoUser.getSession((err, res) => {
          if (err) reject(err);
          cognitoUser.setSignInUserSession(res);
          resolve(res);
        });
      });
    } catch (error) {
      return null;
    }
  }

  async refreshSession(username: string, refreshToken: string) {
    return new Promise((res, rej) => {
      this.getCognitoUser(username).refreshSession(
        new CognitoRefreshToken({ RefreshToken: refreshToken }),
        (err, result) => {
          if (err) {
            rej(err);
          } else {
            res(result);
          }
        },
      );
    });
  }
}

const Auth = new AuthClass();

export default Auth;
