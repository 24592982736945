'use client';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ProductState {
  product_id: number;
  code: string;
}

const initialState: ProductState = {
  product_id: 0,
  code: '',
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    updateProduct: (state, action: PayloadAction<ProductState>) => {
      state.product_id = action.payload.product_id;
      state.code = action.payload.code;
    },
  },
});

export const { updateProduct } = productSlice.actions;

export default productSlice.reducer;
