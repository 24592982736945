import { createSlice } from '@reduxjs/toolkit';

interface AgeGateState {
  // TODO: this should a boolean
  isHidden?: string;
}

const initialState: AgeGateState = {
  isHidden: 'false',
};

const ageGateSlice = createSlice({
  name: 'ageGate',
  initialState,
  reducers: {
    handleHiddenAgeGate: (state, action) => {
      state.isHidden = action?.payload;
    },
  },
});

export const { handleHiddenAgeGate } = ageGateSlice.actions;

export default ageGateSlice.reducer;
