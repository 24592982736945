import { SegmentEvents } from '../constants/forms';
import { ICartProductAddedEvent, SegementTrackEvent } from './segment-helper';

export enum SegementAddToCartTriggerSource {
  NON_PDP = 'non-product-detail-page ',
  PDP = 'product-detail-page ',
}

interface IAddToCartClickedEvent {
  eventItem: ICartProductAddedEvent;
  cartUUID: string;
}

export const addToCartClicked = (value: IAddToCartClickedEvent) => {
  try {
    const data: ICartProductAddedEvent = {
      placement: value.eventItem.placement || 1,
      cart_uuid: value.cartUUID,
      product_position: value.eventItem.product_position,
      product_id: value.eventItem.product_id,
      product_name: value.eventItem.product_name,
      product_brand: value.eventItem.product_brand,
      product_category: value.eventItem.product_category,
      product_lineage: value.eventItem.product_lineage,
      product_weight: value.eventItem.product_weight,
      product_actual_price: value.eventItem.product_actual_price,
      product_quantity: value.eventItem.product_quantity,
      product_offer_applied: value.eventItem.product_offer_applied,
      product_discounted_price: value.eventItem.product_discounted_price,
      add_to_cart_trigger: value.eventItem.add_to_cart_trigger,
    };
    return SegementTrackEvent(SegmentEvents.PRODUCT_ADDED_TO_CART, data, { sendGeoData: true });
  } catch (err) {
    throw Error(err);
  }
};
