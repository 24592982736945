'use client';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface HostState {
  host: string;
  isCurrentHostOH: string;
}

const initialState: HostState = {
  // TODO: remove this hardcode (HOST)
  host: 'http://localhost:3000',
  // host: 'https://dev-headless.risecannabis.io',
  isCurrentHostOH: 'MAIN',
};

const hostSlice = createSlice({
  name: 'host',
  initialState,
  reducers: {
    updateHost: (state, action: PayloadAction<HostState>) => {
      state.host = action.payload.host;
      state.isCurrentHostOH = action?.payload?.isCurrentHostOH;
    },
  },
});

export const { updateHost } = hostSlice.actions;

export default hostSlice.reducer;
